import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header";
import { FeaturedArea } from "../../components/FeaturedArea";
import { AboutUs } from "../../components/AboutUs";
import { Services } from "../../components/Services";
import { MyWorkExperience } from "../../components/MyWorkExperience";
import { EducationAndSkills } from "../../components/EducationAndSkills";
import { Portfolio } from "../../components/Portfolio";
import { FreelanceWork } from "../../components/FreelanceWork";
import { Testimonials } from "../../components/Testimonials";
import { BlogPost } from "../../components/BlogPosts";
import { Partners } from "../../components/Partners";
import { ContactInfoProfiles } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { BottomBar } from "../../components/BottomBar";
import { useParams } from "react-router-dom";
import axios from 'axios';


const HomePage = () => {
  const {Uname} = useParams();
  const [user, setUser] = useState({});
  const [title, setTitle] = useState("My Webfolio");
  useEffect(() => {
    axios.get(`http://localhost:4000/user/${Uname}`).then((res) => {
      setUser(res.data.user);
      setTitle(res.data.user.username + " - My Webfolio")
    })
  }
  , [])
 
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header></Header>
      <FeaturedArea user={user}></FeaturedArea>
      <AboutUs user={user}></AboutUs>
      <Services></Services>
      <MyWorkExperience></MyWorkExperience>
      <EducationAndSkills></EducationAndSkills>
      <Portfolio></Portfolio>
      <FreelanceWork></FreelanceWork>
      <Testimonials></Testimonials>
      <BlogPost></BlogPost>
      <Partners></Partners>
      <ContactInfoProfiles></ContactInfoProfiles>
      <ContactUs></ContactUs>
      <BottomBar></BottomBar>
    </Fragment>
  );
};

export default HomePage;
