import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactUs2 } from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { Partners2 } from "../../components/Partners";
import defaultData from "../default.json"

const ErrorPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Maintainence - {defaultData.name}
        </title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Maintainence"
        hometitle="Home"
        homeURL="/"
        currentPage="Maintainence"
      ></PageTitle>
     
      <Partners2></Partners2>
      <ContactUs2></ContactUs2>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default ErrorPage;
