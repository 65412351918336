import { BrowserRouter, Routes, Route } from "react-router-dom";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import { BlogDetail, BlogPage, BlogPage2 } from "../pages/BlogPages";
import { ContactPage } from "../pages/ContactPage";
import { ErrorPage , MaintainencePage } from "../pages/ErrorPage";
import { HomePage, HomePage2 } from "../pages/HomePages";
import { PlansPage } from "../pages/PlansPage";
import { PortfolioDetail, PortfolioPage, PortfolioPage2, PortfolioPage3 } from "../pages/PortfolioPages";
import { ServiceDetail, ServicePage } from "../pages/ServicePages";
import axios from 'axios';
import { useState } from "react";
import { useEffect } from "react";

const RouterLinks = () => {
const [status, setStatus] = useState(true); 
const [page ,setPage] = useState();


useEffect(() => {
  axios.get('http://localhost:4000/status').then((res) => {  
    if(res.data.status !== 'main'){
      setStatus(false);
      switch (res.data.status) {
        case 'MaintainencePage':
          setPage(MaintainencePage);
          break;
        case 'ErrorPage':
          setPage(ErrorPage);
          break;
        default:
          setStatus(true)
      }
  }
}
  );
// axios.get('http://localhost:4000/user').then((res) => {
  
// })

}, [])
//This comment is for testing purpose

  if (status === true) {
    return (
      <>
        <BrowserRouter>
          <SmoothScroll>
            <Routes>
              <Route path="/:Uname/" element={<HomePage></HomePage>} />
              <Route path="/home2" element={<HomePage2></HomePage2>} />
              <Route path="/blog" element={<BlogPage></BlogPage>} />
              <Route path="/blog/:Id" element={<BlogDetail></BlogDetail>} />
              <Route path="/blog2" element={<BlogPage2></BlogPage2>} />
              <Route path="/plans" element={<PlansPage></PlansPage>} />
              <Route path="/service" element={<ServicePage></ServicePage>} />
              <Route path="/service/:Id" element={<ServiceDetail></ServiceDetail>} />
              <Route path="/portfolio" element={<PortfolioPage></PortfolioPage>} />
              <Route path="/portfolio/:Id" element={<PortfolioDetail></PortfolioDetail>} />
              <Route path="/portfolio2" element={<PortfolioPage2></PortfolioPage2>} />
              <Route path="/portfolio3" element={<PortfolioPage3></PortfolioPage3>} />
              <Route path="/contact" element={<ContactPage></ContactPage>} />
              <Route path="/404" element={<ErrorPage></ErrorPage>} />
              <Route path="*" element={<ErrorPage></ErrorPage>} />
            </Routes>
          </SmoothScroll>
        </BrowserRouter>
      </>
    );
  } else {
    return(
      <>
      <BrowserRouter>
        <SmoothScroll>
          <Routes>
            <Route path="*" element={page} />
          </Routes>
        </SmoothScroll>
      </BrowserRouter>
    </>
    )
  }

};

export default RouterLinks;
